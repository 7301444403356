import { getSession } from '@auth0/nextjs-auth0';
import Loader from 'atoms/Loader';
import axios from 'axios';
import {
    signInWithCustomToken
} from 'firebase/auth';
import {
    doc,
    serverTimestamp
} from 'firebase/firestore';
import { complianceRoles, windowFeatures } from 'lib/constants';
import LogRocket from 'logrocket';
import TermsAndCondition from 'organisms/TermsAndCondition';
import { useEffect, useState } from 'react';
import { getAuth, removeAuth, setAuth } from 'services/auth.service';
import {
    db,
    auth as firebaseAuth,
    getDocs, setDocs, updateDocs
} from 'services/firebase.service';
import { socketConnect, socketEmit } from 'services/socket';
import { loginUser } from 'services/user.service';

const saveClientDetails = async () => {
    let clientDetails;
    try {
        const apiResponse = await axios('https://ipapi.co/json/');
        if (apiResponse.status === 200) {
            clientDetails = apiResponse.data;

            socketEmit('/users/sessions', { clientDetails: { ...clientDetails, timestamp: Date.now() } });
        }
        return apiResponse;
    } catch (e) {
        console.error('Error fetching client details:', e);
    } finally {
        if (!clientDetails) socketEmit('/users/sessions', { clientDetails: { timestamp: Date.now() } });
    }
};

const handleUserRedirection = () => {
    if (complianceRoles.includes(getAuth()?.role)) {
        window.location.href = '/compliance/users';
    } else {
        window.open(`${process.env.NEXT_PUBLIC_UI_URL}hoot`, '_blank', windowFeatures);
        window.location.href = '/dashboard';
    }
};

export default function EmptyComponent({ isAuthSession, idToken }) {
    const [showTerms, setShowTerms] = useState(false);
    const [isTermsAgreed, setIsTermsAgreed] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleTerms = () => {
        setIsTermsAgreed(!isTermsAgreed);
    };

    const handleUserLogin = async (idToken, terms = false) => {
        setLoading(true);
        const response = await loginUser({ terms }, idToken);
        setLoading(false);

        if (response.status && response.data.entity) {
            const {
                email, name, companyName, role, sessionId
            } = response.data.entity;

            LogRocket.identify(response.data.entity.id, {
                email, name, companyName, role, sessionId
            });
            const auth = JSON.stringify({ ...response.data.entity, token: idToken, fbToken: undefined });
            setAuth(auth, {
                expires: new Date(Date.now() + 2592000 * 1000),
                secure: true
            });

            // setRefreshToken(response.data.entity.refreshToken);
            let res;
            try {
                res = await signInWithCustomToken(
                    firebaseAuth,
                    response.data.entity.fbToken
                );
            } catch (loginError) {
                console.error('Login error:', loginError);
            }
            try {
                if (res) {
                    const userSnapshot = await getDocs(doc(db, 'users', response.data.entity.id));
                    if (!userSnapshot || !userSnapshot.exists()) {
                        await setDocs(doc(db, 'users', response.data.entity.id), {
                            name: response.data.entity.name,
                            sname: response.data.entity.name.toLowerCase(),
                            createdAt: serverTimestamp(),
                            profileUrl: response.data.entity.profileUrl,
                            companyName: response.data.entity.companyName,
                            uid: res.user.uid,
                            lastSeen: serverTimestamp(),
                            metadata: {
                                userId: response.data.entity.id,
                                companyId: response.data.entity?.companyId,
                                role: response.data.entity.role
                            },
                            role: null,
                            updatedAt: serverTimestamp()
                        });
                    } else if (userSnapshot.exists()) {
                        const userDocRef = doc(db, 'users', response.data.entity.id);
                        await updateDocs(userDocRef, {
                            name: response.data.entity.name,
                            sname: response.data.entity.name.toLowerCase(),
                            profileUrl: response.data.entity.profileUrl,
                            companyName: response.data.entity.companyName,
                            lastSeen: serverTimestamp(),
                            metadata: {
                                userId: response.data.entity.id,
                                companyId: response.data.entity?.companyId,
                                role: response.data.entity.role
                            },
                            updatedAt: serverTimestamp()
                        });
                    }
                }
            } catch (error) { console.log(error); }
            socketConnect();
            await saveClientDetails();
            handleUserRedirection();
        } else if (response?.error?.error === 'terms') {
            setShowTerms(true);
        } else {
            window.location.href = '/api/auth/logout';
        }
    };

    useEffect(() => {
        if (isAuthSession) {
            if (getAuth()?.token) {
                window.location.href = '/dashboard';
            } else if (idToken) {
                handleUserLogin(idToken);
            }
        } else {
            if (getAuth()?.token)removeAuth();
            window.location.href = '/api/auth/login';
        }
    }, [isAuthSession]);
    return (
        <><TermsAndCondition show={showTerms} buttonLoading={loading}
            onConfirm={() => handleUserLogin(idToken, true)}
            handleChange={handleTerms} disabled={!isTermsAgreed} isSelected={isTermsAgreed} onClose={() => setShowTerms(false)} />
        {(!showTerms || loading) && <Loader type='type3' opacity={0.65} />}</>);
}

export async function getServerSideProps({ req, res }) {
    const session = await getSession(req, res);

    return {
        props: {
            isAuthSession: !!session?.user, idToken: session?.idToken || null
        }
    };
}
